import React, { useEffect, useState, memo } from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const QuickLinks = [
    {
        label: "Home",
        value: "Home",
        navlink: "/",
    },
    {
        label: "About us",
        value: "Aboutus",
        navlink: "/AboutUs",
    },
    {
        label: "Request A Demo",
        value: "RequestDemo",
        navlink: "/RequestDemo",
    },
];

const Legal = [
    {
        label: "Privacy Policies",
        value: "PrivacyPolicy",
        navlink: "/PrivacyPolicy",
    },
];

interface FooterProps {
    currentPage: string;
    setCurrentPage: (page: string) => void;
    footerSelectPage?: string;
}

const FooterComponent: React.FC<FooterProps> = ({ currentPage, setCurrentPage }) => {
    const [selectedFooter, setSelectedFooter] = useState("Home");

    const handleSetActiveNavigation = (nav: string) => {
        setSelectedFooter(nav);
        setCurrentPage(nav);
    };

    useEffect(() => {
        setSelectedFooter(currentPage);
    }, [currentPage]);

    return (
        <div className="footer-bg text-start">
            <div className="col-12 d-flex justify-content-center px-2">
                <div className="width-full-page pb-4">
                    <div className="row ">
                        <div className="col-md-6 pt-4 pe-2 footer-top-content custom-text-center">
                            {/* <img src={ } alt="Radiant Tech Logo" /> */}
                            <span className="span-with-violet"><h3>Radiant Tech</h3></span>
                            <div className="custom-footer-text">
                                <div className="footer-text">
                                    Revolutionizing Compliance with AI. Efficient AML solutions that cut through complexity Compliance made easy, trusted, and transparent.                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="row home-step-card">
                                <div className="col-6 pt-4">
                                    <div className="footer-heading-links">Quick Links</div>
                                    <div className="pt-3">
                                        {QuickLinks.map((item) => (
                                            <div key={item.value}>
                                                <Link
                                                    to={item.navlink}
                                                    className={`footer-link ${selectedFooter === item.value ? "activefooter" : ""}`}
                                                    onClick={() => handleSetActiveNavigation(item.value)}
                                                >
                                                    {item.label}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-6 pt-4">
                                    <div className="footer-heading-links">Legal</div>
                                    <div className="pt-3">
                                        {Legal.map((item) => (
                                            <div key={item.value}>
                                                <Link
                                                    to={item.navlink}
                                                    className={`footer-link ${selectedFooter === item.value ? "activefooter" : ""}`}
                                                    onClick={() => handleSetActiveNavigation(item.value)}
                                                >
                                                    {item.label}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-4 d-flex justify-content-center footer-credit-text-div px-2">
                <div className="width-full-page text-center d-flex justify-content-center footer-credit-text pb-4 d-flex">
                    © 2024 Radiant Tech. All Rights Reserved.
                </div>
            </div>
        </div>
    );
};

export default memo(FooterComponent);
