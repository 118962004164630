import { aboutUsImg } from "../../assets";
import DemoComponent from "../../components/DemoComponent";
import "./aboutUs.css";
const AboutUs = () => {

    return (
        <>
            <div className="col-12 d-flex justify-content-center text-start pb-4">

                <div className="row width-full-page pb-5 ">
                    <div className="col-md-12 mt-5 mb-5 ">
                        <div className="row d-flex justify-content-center">
                            <div>
                                <div className="col-md-12">
                                    <div className="sub-div-headings d-flex mt-2 justify-content-center ">
                                        <span>About </span>
                                        <span className="span-with-violet">
                                            Us
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row about-us-container mt-4">
                                <div className="col-12 p-3">
                                    <div className="row">
                                        <div className="col-md-4  align-content-center text-center p-2">
                                            <img src={aboutUsImg} alt="" className="img-fluid responsive-img" />
                                        </div>
                                        <div className="col-md-8 about-us-content-container inner-text-content-three p-3">
                                            <div className="mt-2">
                                                At Radiant Tech, we are a forward-thinking fin-tech company committed to reshaping the financial landscape with innovative, AI-powered solutions. Our mission is to empower banks and financial institutions by streamlining the complex regulatory processes, with a primary focus on revolutionizing the Anti-Money Laundering (AML) operations.
                                            </div>
                                            <div className="mt-2">
                                                Harnessing the power of machine learning and advanced data analysis, Radiant Tech provides cutting-edge technologies that enable banks to enhance their compliance workflows, significantly reduce operational costs, and elevate customer satisfaction. We understand the challenges that come with regulatory compliance, and our solutions are designed to make these processes not only simpler but also more transparent and efficient.
                                            </div>
                                            <div className="mt-2">
                                                At Radiant Tech, we believe that when compliance becomes seamless, financial institutions can dedicate more time to what truly matters—delivering exceptional customer service while upholding the highest standards of regulatory adherence. Our vision is to create a world where compliance is no longer a burden but a strategic advantage, empowering banks to navigate the evolving financial landscape with confidence and ease.
                                            </div>
                                            <div className="mt-2">
                                                Join us in transforming compliance into a competitive edge, allowing your institution to thrive in today’s dynamic market.
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </div>
            <DemoComponent />
        </>

    );
};

export default AboutUs;
