import "./democomponent.css";

const DemoComponent = () => {
    const handleClick = () => {
        window.location.href = "mailto:sajith.mohammed@iconnect101.com.";
    };
    return (
        <>
            <div className="demo-container p-5">
                <h4>Revolutionize Your AML Compliance with Radiant Tech!</h4>
                <div className="col-md-8"><span>Learn how Radiant Tech's AML Process Accelerator can transform your compliance operations, reduce costs, and enhance customer satisfaction. Join leading banks in embracing the future of AML compliance.
                </span></div>
                <button className="demo-button-bottom" onClick={handleClick}>
                    Request a Demo
                </button>            </div>
        </>
    );
};

export default DemoComponent;
